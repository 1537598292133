module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/asset/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5626a93ec8427d1912187b238fdd056"},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"publicApiKey":"YWI0ODQ4MmUtMjc1OS00M2Y3LTk1M2MtMzM5MjY5NDc4YjI3NjM4NTI1NjIzMzcwNDk3ODAw","defaultLang":"fr","currency":"eur","templatesUrl":"/snipcart-templates.html","openCartOnAdd":true,"useSideCart":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
