exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boutique-js": () => import("./../../../src/pages/boutique.js" /* webpackChunkName: "component---src-pages-boutique-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-domaine-js": () => import("./../../../src/pages/domaine.js" /* webpackChunkName: "component---src-pages-domaine-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-presse-js": () => import("./../../../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-vins-js": () => import("./../../../src/pages/vins.js" /* webpackChunkName: "component---src-pages-vins-js" */),
  "component---src-templates-game-page-js": () => import("./../../../src/templates/game-page.js" /* webpackChunkName: "component---src-templates-game-page-js" */),
  "component---src-templates-produit-page-js": () => import("./../../../src/templates/produit-page.js" /* webpackChunkName: "component---src-templates-produit-page-js" */)
}

